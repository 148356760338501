import { Component, Host, h } from '@stencil/core';

@Component({
  tag: 'x-scrollable',
  styleUrl: 'x-scrollable.sass',
})
export class XScrollable {
  render() {
    return (
      <Host>
        <slot></slot>
      </Host>
    );
  }
}
